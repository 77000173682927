.container{
    margin:2rem 0 4rem 0;
}

@media(max-width:90em){
    .container{
        margin:1rem 0 1rem 0;
    }
}

@media(max-width:55em){
    .container{
        margin:1rem;
    }
}
