.container {
    margin: 0;
    padding:0;
}

.settingsContainer{
}

.label{
    font-size: 2rem;
}
.title{
    font-size: 2rem;
}
.itemContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding:0;
    margin-bottom: .5rem;
}

@media(max-width:55rem){
    .label{
        font-size: 1.5rem;
    }
    .items{
        font-size: 1.35rem;
    }
    .title{
        font-size: 1.5rem;
    }
}