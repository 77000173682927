.container{
    padding:0;
    margin:0;
}

.helpContainer{
}

.label{
    font-size: 2rem;
}
.title{
    font-size: 2rem;
}


@media(max-width:55em){
    .label{
        font-size: 1.5rem;
    }
    .title{
        font-size: 1.5rem;
    }
}