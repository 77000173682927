.container {
    margin: 0;
    padding-bottom:2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width:100%;
}

.infoContainer{
    margin: 0 6rem 0 6rem;
    text-align: left;
}

.label{
    line-height: 2.8rem;
    font-size: 2rem;
    margin:1.5rem 0 0 0;
}
.label2{
    line-height: 2.8rem;
    font-size: 2rem;
    margin:2rem 0 .5rem 0;
}

.inputContainer{
    margin:.5rem 0 0 0;
    padding:0 1rem 0 1rem;
    width:60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.codeInput{
    width:95%;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}
.codeInput:focus{
    outline:none;
}

.passwordInput{
    margin:0;
    width:85%;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}
.passwordInput:focus{
    outline:none;
}


.infoIcon{
    margin: 0 0 .4rem 1rem;
    width:3rem;
    height:3rem;
}

.infoText{
    font-style: italic;
    margin:0 0 .5rem 0;
    font-size: 1.5rem;
}

.passwordRow{
    display: flex;
    flex-direction: row;
    width:100%;
    margin:0 0 .5rem 0 ;
    padding:0;
    align-items: flex-end;
    justify-content: flex-start;
}

.row{
    display: flex;
    flex-direction: row;
    width:100%;
    margin:0;
    padding:0;
    align-items: center;
    justify-content: space-between;
}
.logo{
    width:5rem;
    margin-left: 1rem;
    height:5rem;
}

.linkContainer{
    width:60%;
    margin:.5rem 0;
    text-align: right;
}

.passwordLink{
    font-size: 2rem;
    cursor:pointer;
}

.passwordLink:hover
{
    font-weight: bold;
}
.error{
    color:orangered;
    font-size: 2rem;
    margin:1rem 0;
}

.noError{
    color:#111111;
    font-size: 2rem;
    margin:1rem 0;
}
.btnContainer{
    margin-top: 1rem;
}

@media(max-width:55em){

    .inputContainer{
        margin-top: 0;
        margin-bottom: 1.5rem;
        width: 70%;
       
    }

    .infoContainer{
        width:85%;
        margin-top: .5rem;
    }

    .label{
        font-size: 1.5rem;
        margin-bottom: .1rem;
        margin-top:0;
    }
    .label2{
        font-size: 1.5rem;
        margin-bottom: .1rem;
        margin-top: 0;
    }
    .info{
        font-size: 1.5rem;
        margin:2rem 0 ;
        line-height: 2.5rem;
        text-align: left;
    }
    .logo{
        width:3.5rem;
        height: 3.5rem;;
    }
    .passwordInput{
        padding:.5rem;
        font-size: 1.5rem;
    }
    .codeInput{
        font-size: 1.5rem;
        padding:.5rem;

    }
    .error{
        color:orangered;
        font-size: 1.2rem;
        margin:1rem;
    }
    
    .noError{
        color:#111111;
        font-size: 1.2rem;
        margin:1rem;
    }  
    .infoIcon{
        margin: 0 0 .5rem .5rem;
        width:2.5rem;
        height:2.5rem;
    }
    .passwordRow{
        margin-bottom: 0;
    }
}