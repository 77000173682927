
.container {
  margin: 1rem 0 0 0;
  padding:1rem .5rem 0 .5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  background-color: #FAFAFA;
  height: auto;
  width: 15rem;
}

/**************************/
/* BELOW 120em             */
/**************************/


@media (max-width: 90em) {
  .logo {
    width: 12rem;
  }
  .tagline{
    font-size: 1.7rem;
  }
}


@media (max-width: 50em) {
  .logo {
    width: 10rem;
  }
  .tagline{
    font-size: 1.3rem;
  }
}