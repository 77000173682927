.container{
    width:60%;
    background-color: #dddddd;
    padding:0;
    border-style: solid;
    border-color:#bbbbbb;
    cursor:pointer;
    border-radius: 1rem;
}
.container:hover{
    background-color: #cccccc;
}
.label{
    margin:1.5rem;
    font-size: 2rem;
    text-align: left;
}

@media(max-width:55em){
    .label{
        font-size: 1.5rem;
    }
    .container{
        width:80%;
    }
}