.container{
    padding:0;
    margin:0;
}
.titleContainer{
    /* margin:2rem 0 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; */
}

.title{
    font-size: 2rem;
}
.label{
    font-size: 2rem;
}

@media(max-width:55em){
    .title{
        font-size: 1.5rem;
    }
    .label{
        font-size: 1.5rem;
    }
}