.container{
    background-color: #111111;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:2rem 2rem;

}

.left{
    width:19rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left:2rem;
    justify-content: flex-start;
    
}
.center{
    margin-left:2rem;
}

.right{
    margin-right: 2rem;
    width:19rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap:2rem;

}

.logo{
    width:5rem;
    height:5rem;
}

.headerLogo{
    height:10rem;
}


@media(max-width: 80rem){
    .headerLogo{
        height:8rem;
    }
    .logo{
        width:3rem;
        height:3rem;
    }
    .right{
        width:10rem;
        gap:1rem;
        margin-right:1rem;
    }
    .center{
        margin-left:1rem;
    }
    .left{
        width:10rem;
        margin-left:1rem;
    }
}

@media(max-width: 55rem){
    .headerLogo{
        height:4rem;
    }
    .logo{
        width:2rem;
        height:2rem;
    }
    .right{
        width:10rem;
        gap:1rem;
        margin-right:1rem;
    }
    .center{
        margin-left:1rem;
    }
    .left{
        width:10rem;
        margin-left:1rem;
    }
}

@media(max-width: 32rem){
    .container{
        padding:1rem .5rem;
    }
    .headerLogo{
        height:2.5rem;
    }
    .logo{
        width:1.75rem;
        height:1.75rem;
    }
    .right{
        width:8rem;
        gap:.5rem;
        margin-right:1rem;
    }
    .center{
        margin-left:.5rem;
    }
    .left{
        width:8rem;
        margin-left:1rem;
    }
}