
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width:100vw;
    height:100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    margin:0 8rem;
    border-width: .4rem;
    border-color:#111111;
    border-style: solid;
    background-color: #fafafa;
    padding: 2rem;
    border-radius: 1rem;
    position: relative;
  }
.info{
    color:#111111;
    font-size: 2rem;

}

.btnContainer{
    margin-top: 1rem;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:4rem;
  }
  

  
  @media (max-width: 90em) {
    .btnContainer{
      gap:3rem;
    }
  }

@media(max-width:80em){
    .info{
        font-size: 1.7rem;
    }
 }

 @media(max-width:55em){
    
    .btnContainer{
        gap:1rem;
        margin-top: 0;
      }

    .info{
        font-size: 1.3rem;
    }
    .container{
        margin:0 2rem;
        padding:.5rem .5rem;
    }
 }

