.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 4rem;
}
.leftArrow{
    transform: rotate(180deg);
    width:4rem;
    opacity:1;
}
.leftArrowDisabled{
    transform: rotate(180deg);
    width:4rem;
    opacity:.2;
}
.rightArrow{
    width:4rem;
    opacity:1;
}
.rightArrowDisabled{
    width:4rem;
    opacity:.2;
}
.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    column-gap: 1rem;
}

@media(max-width:90em){
    .container{
        column-gap:2rem;
    }
}
@media(max-width:55em){
    .container{
        column-gap:.5rem;
    }
    .leftArrow{
        transform: rotate(180deg);
        width:3rem;
    }
    .leftArrowDisabled{
        transform: rotate(180deg);
        width:3rem;
    }
    .rightArrow{
        width:3rem;
    }
    .rightArrowDisabled{
        width:3rem;
    }
    .row{
        column-gap: .5rem;
    }
}