.container{
    margin:3rem 0;
}
.photoImg{
    width:16rem;
    height:16rem;
}

@media(max-width:90em){
    .container{
        margin:2rem 0;
    }
    .photoImg{
        width:12rem;
        height:12rem;
    }
}

@media(max-width:55em){
    .container{
        margin:1rem 0;
    }
    .photoImg{
        width:5rem;
        height:5rem;
    }
}