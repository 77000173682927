.container {
    margin: 0;
    padding:0;
}

.profileContainer{
    margin: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    font-size: 2rem;;
}

.label{
    font-size: 2rem;
    margin: 0 0 1rem 0;
}

.nameContainer{
    margin:2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:2rem;
}
.logo{
    width:3rem;
    height:3rem;
}
.error{
    color:orangered;
    font-size: 2rem;
    margin:1rem 0;
}

@media(max-width:55em){
    .error{
        font-size: 1.5rem;
    }
    .logo{
        width:2rem;
        height:2rem;
    }
    .label{
        font-size:1.5rem;
    }
    .title{
        font-size: 1.5rem;;
    }
}