.container{
    display: flex;
    padding:0;
    margin:0;
    flex-direction: row;
    align-items: center;
    border-width: .2rem;
    border-style: solid;
    border-color: #aaaaaa;
    border-bottom: none;;

}
.empty1{
    color:#fafafa;
    font-size: 1.5rem;
    margin:0;
    padding:0 1rem;
    width:25rem;
    height:4rem;
    text-align: left;
    border-right: .2rem solid #aaaaaa;
}
.container:last-child{
    border-bottom: .2rem solid #aaaaaa;
    margin-bottom: 2rem;
}

.column1{
    margin:0;
    padding:0 1rem;
    font-size:1.5rem;
    width:25rem;
    height:4rem;
    text-align: left;
    border-right: .2rem solid #aaaaaa;
}

.empty2{
    margin:0;
    font-size:1.5rem;
    padding:0;
    height:4rem;
    width:4.5rem;
    text-align: center;
    border-right: .2rem solid #aaaaaa;
    background-color:#f9dfc3;
    color:#f9dfc3;
}

.column2{
    background-color:#f9dfc3; 
    margin:0;
    padding:0;
    font-size:1.5rem;
    height:4rem;
    width:4.5rem;
    text-align: center;
    border-right: .2rem solid #aaaaaa;
}

.empty3{
    background-color:#dddddd;
    margin:0;
    padding: 0;
    font-size:1.5rem;
    height:4rem;
    width:8.5rem;
    text-align: center;
    border-right: .2rem solid #aaaaaa;
    color:#dddddd;
}

.column3{
    background-color:#dddddd;
    padding:0;
    margin:0;
    font-size:1.5rem;
    height:4rem;
    width:8.5rem;
    text-align: center;
    border-right: .2rem solid #aaaaaa;
}

.column4{
    background-color:#f8f5b2;
    margin:0;
    padding:0;
    font-size:1.5rem;
    height:4rem;
    width:9.5rem;
    text-align: center;
    border-right: .2rem solid #aaaaaa;
}

.column5{
    background-color:#dddddd;
    margin:0;
    padding:0;
    font-size:1.5rem;
    height:4rem;
    width:9.5rem;
    text-align: center;
    border-right: .2rem solid #aaaaaa;
}

.column6{
    background-color: #d0e8fa;
    margin:0;
    padding:0;
    font-size:1.5rem;
    height:4rem;
    width:11.5rem;
    text-align: center;
}

.empty4{
    background-color: #f8f5b2;
    margin:0;
    padding:0;
    font-size:1.5rem;
    height:4rem;
    width:9.5rem;
    text-align: center;
    border-right: .2rem solid #aaaaaa;
    color:#f8f5b2;
}

@media(max-width:90em){
    
    .empty1{
        font-size: 1.2rem;
        margin:0;
        padding:0 0 0 .5rem;
        width:17.5rem;
        height:3.5rem;
    }
    .container:last-child{
        margin-bottom: 2rem;
    }
    
    .column1{
        font-size:1.2rem;
        padding:0 0 0 .5rem;
        width:17.5rem;
        height:3.5rem;
    }
    
    .empty2{
        font-size:1.2rem;
        height:3.5rem;
        width:3.5rem;
    }
    
    .column2{
        font-size:1.2rem;
        height:3.5rem;
        width:3.5rem;
    }
    
    .empty3{
        font-size:1.2rem;
        height:3.5rem;
        width:6.5rem;
    }
    
    .column3{
        font-size:1.2rem;
        height:3.5rem;
        width:6.5rem;
    }
    
    .column4{
        font-size:1.2rem;
        height:3.5rem;
        width:7.5rem;
    }
    
    .column5{
        font-size:1.2rem;
        height:3.5rem;
        width:7rem;
    }
    
    .column6{
        font-size:1.2rem;
        height:3.5rem;
        width:8.5rem;
    }
    
    .empty4{
        font-size:1.2rem;
        height:3.5rem;
        width:7.5rem;
    }
}

@media(max-width:55em){
    .container{
        border-width: .1rem;
    }
    .container:last-child{
        border-bottom: .1rem solid #aaaaaa;
        margin-bottom: 1rem;
    }

    .empty1{
        font-size: .8rem;
        margin:0;
        padding:0 0 0 .1rem;
        width:4.75rem;
        height:7rem;
        border-right: .1rem solid #aaaaaa;
    }

    .container:last-child{
        margin-bottom: 1rem;
    }
    
    .column1{
        font-size:.8rem;
        padding:0 0 0 .1rem;
        width:4.75rem;
        height:7rem;
        border-right: .1rem solid #aaaaaa;
    }
    
    .empty2{
        font-size:.8rem;
        height:7rem;
        width:2rem;
        border-right: .1rem solid #aaaaaa;
    }
    
    .column2{
        font-size:.8rem;
        height:7rem;
        width:2rem;
        border-right: .1rem solid #aaaaaa;
    }
    
    .empty3{
        font-size:.8rem;
        height:7rem;
        width:4rem;
        border-right: .1rem solid #aaaaaa;
    }
    
    .column3{
        font-size:.8rem;
        height:7rem;
        width:4rem;
        border-right: .1rem solid #aaaaaa;
    }
    
    .column4{
        font-size:.8rem;
        height:7rem;
        width:5rem;
        border-right: .1rem solid #aaaaaa;
    }
    
    .column5{
        font-size:.8rem;
        height:7rem;
        width:4.5rem;
        border-right: .1rem solid #aaaaaa;
    }
    
    .column6{
        font-size:.8rem;
        height:7rem;
        width:3.5rem;
    }
    
    .empty4{
        font-size:.8rem;
        height:7rem;
        width:5rem;
        border-right: .1rem solid #aaaaaa;
    }
}
