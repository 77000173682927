.container {
    margin: 0;
    padding:0;
}

.title{
    font-size: 2rem;
}
.titleContainer{
    margin:2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.categories{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.categoryItem{
    width:50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #dddddd;
    cursor:pointer;
    margin-bottom: .2rem;
    padding:0 2rem;
}

.categoryLabel{
    font-size: 2rem;
    margin:1rem 0;
}

.nameContainer{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap:1rem;
}

.logoContainer{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap:1rem;
}
.logo{
    width:3rem;
    height:3rem;
}
.backBtn{
    width:4rem;
    height:4rem;
}
.addBtn{
    width:4rem;
    height:4rem;
}
.noBackBtn{
    width:4rem;
    height:4rem;
    opacity: 0;;
}
.rowContainer{ 
    width:53%;
    margin:0 auto;
}
.topBtnContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .1rem;
    cursor: pointer;
}
.backBtnContainer:hover{
    opacity: .8;
}


.categoryItem:hover{
    background-color: #cccccc;
}
.categoryLabel{
    font-size: 2rem;
    text-align: left;
}

.error{
    color:orangered;
    font-size: 2rem;
}
.errorContainer{
    margin:0 4rem 2rem 4rem;
}


@media(max-width:120rem){
    .categoryItem{
        width:70%;
    }
    .rowContainer{
        width:74%;
       
    }
}
@media(max-width:55rem){
    .title{
        font-size: 1.5rem;
    }
    .categoryLabel{
        font-size: 1.5rem;
    }
    .categoryItem{
        width:80%;
    }
    .rowContainer{
        width:90%;
    }
    .error{
        font-size: 1.5rem;
    }
    .logo{
        width:2rem;
        height:2rem;
    }
    .backBtn{
        width:3rem;
        height:3rem;
    }
    .addBtn{
        width:3rem;
        height:3rem;
    }
    
}

@media(max-width:35rem){
    .categoryItem{
        width:80%;
    }
    .rowContainer{
        width:94%;
    }
}