.container{
    margin:2rem 0 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleText{
    font-size: 2rem;
}

@media(max-width:55em){
    .titleText{
        font-size: 1.5rem;
    }
}