.btn {
    margin: 1rem;
    text-align: center;
    padding:.2rem 3rem;
    background-color: #FAFAFA;
    border-width: .2rem;
    border-style: solid;
    border-radius:20rem;
    border-color: #111111;
    cursor: pointer;
  }
  .btn:hover{
      background: #cccccc;
  }

  .btnSmall {
    margin: .5rem;
    text-align: center;
    padding:.2rem 2rem;
    background-color: #FAFAFA;
    border-width: .2rem;
    border-style: solid;
    border-radius:20rem;
    border-color: #111111;
    cursor: pointer;
  }
  .btnSmall:hover{
      background: #cccccc;
  }

  .btnSmallDisable {
    margin: .5rem;
    text-align: center;
    padding:.2rem 2rem;
    background-color: #FAFAFA;
    border-width: .2rem;
    border-style: solid;
    border-radius:20rem;
    border-color: #cccccc;
    cursor: default;
  }

  .btnDisable {
    margin: 1rem;
    text-align: center;
    padding:.2rem 3rem;
    background-color: #FAFAFA;
    border-width: .2rem;
    border-style: solid;
    border-radius:20rem;
    border-color: #cccccc;
    cursor: default;
  }

  
  .btnText {
   font-size: 2rem;
   line-height: .5rem;
   color:#111111;
   font-weight: 600;
  }

  .btnTextDisable {
    font-size: 2rem;
    line-height: .5rem;
    color:#cccccc;
    font-weight: 600;
    
   }

   .btnTextSmall {
    font-size: 1.7rem;
    line-height: .5rem;
    color:#111111;
    font-weight: 600;
   }
 
   .btnTextSmallDisable {
     font-size: 1.7rem;
     line-height: .5rem;
     color:#cccccc;
     font-weight: 600;
     
    }

    @media (max-width: 90em) {

      .btn {
        margin: .5rem;
        padding:.1rem 1.5rem;
      }
      .btnSmall {
        margin: .5rem;
        padding:.1rem 1.5rem;
      }
      .btnSmallDisable {
        margin: .5rem;
        padding:.1rem 1.5rem;
      }
    
      .btnDisable {
        margin: .5rem;
        padding:.1rem 1.5rem;
      }    
      .btnText {
       font-size: 1.7rem;
       line-height: .4rem;
      }
    
      .btnTextDisable {
        font-size: 1.7rem;
        line-height: .4rem;
       }
    
       .btnTextSmall {
        font-size: 1.6rem;
        line-height: .4rem;
       }
       .btnTextSmallDisable {
         font-size: 1.6rem;
         line-height: .4rem;
        }
    }

    @media (max-width: 90em) {

      .btn {
        margin: .5rem;
        padding:0 1rem;
      }
      .btnSmall {
        margin: .5rem;
        padding:0 1rem;
      }
      .btnSmallDisable {
        margin: .5rem;
        padding:.0 1rem;
      }
    
      .btnDisable {
        margin: .5rem;
        padding:0 1rem;
      }    
      .btnText {
       font-size: 1.4rem;
       line-height: .3rem;
      }
    
      .btnTextDisable {
        font-size: 1.4rem;
        line-height: .3rem;
       }
    
       .btnTextSmall {
        font-size: 1.3rem;
        line-height: .3rem;
       }
       .btnTextSmallDisable {
         font-size: 1.3rem;
         line-height: .3rem;
        }
    }

    @media (max-width: 55em) {

      .btn {
        margin: .3rem;
        padding:0 .75rem;
      }
      .btnSmall {
        margin: .3rem;
        padding:0 .75rem;
      }
      .btnSmallDisable {
        margin: .3rem;
        padding:.0 .75rem;
      }
    
      .btnDisable {
        margin: .3rem;
        padding:0 .75rem;
      }    
      .btnText {
       font-size: 1.2rem;
       line-height: .2rem;
      }
    
      .btnTextDisable {
        font-size: 1.2rem;
        line-height: .2rem;
       }
    
       .btnTextSmall {
        font-size: 1.1rem;
        line-height: .2rem;
       }
       .btnTextSmallDisable {
         font-size: 1.1rem;
         line-height: .2rem;
        }
    }



    @media (max-width: 40em) {

      .btnSmall {
        margin: .1rem;
        padding:.3rem 1rem;
      }
      .btnSmallDisable {
        margin: .1rem;
        padding:.3rem 1rem;
      }
       .btnTextSmall {
        font-size: 1.1rem;
       }
       .btnTextSmallDisable {
         font-size: 1.1rem;
        }
    }