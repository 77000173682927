
.btnContainer{
  margin-top: 1rem;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:2rem;
}


/**************************/
/* BELOW 120em             */
/**************************/
@media (max-width: 40em) {
  .btnContainer{
    flex-direction: column;
    gap:1rem;
  }
}
