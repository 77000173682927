
.btnContainer{
  margin-top: 1rem;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:4rem;
}

/**************************/
/* BELOW 120em             */
/**************************/

@media (max-width: 90em) {
  .btnContainer{
    gap:3rem;
  }
}

@media (max-width: 55em) {
  .btnContainer{
    gap:1rem;
    margin-top: 0;
  }
}