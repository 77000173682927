
.container {
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width:100%;
}
.titleSection{
    width:75%;
    margin:0 1rem;
}
.label{
    font-size: 2rem;
    margin:0 0 1rem 0;
}

.inputContainer{
    margin:2rem 0 0 0;
    padding:0 1rem 0 1rem;
    width:60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.emailInput{
    width:97.5%;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}
.emailInput:focus{
    outline:none;
}

.passwordInput{
    margin:0;
    width:85%;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}
.passwordInput:focus{
    outline:none;
}


.infoIcon{
    margin: 0 0 .5rem 1rem;
    width:3rem;
    height:3rem;
}

.infoText{
    font-style: italic;
    margin:0 0 .5rem 0;
    font-size: 1.5rem;
}

.passwordRow{
    display: flex;
    flex-direction: row;
    width:100%;
    margin:0 0 .5rem 0 ;
    padding:0;
    align-items: center;
    justify-content: flex-start;
}

.row{
    display: flex;
    flex-direction: row;
    width:100%;
    margin:0;
    padding:0;
    align-items: center;
    justify-content: space-between;
}
.logo{
    width:5rem;
    margin-left: 1rem;
    height:5rem;
    
}

.linkContainer{
    width:60%;
    margin:.5rem 0;
    text-align: right;
}

.passwordLink{
    font-size: 2rem;
    cursor:pointer;
}

.passwordLink:hover
{
    font-weight: bold;
}
.error{
    color:orangered;
    font-size: 2rem;
    margin:1rem 0;
}

.noError{
    opacity:0;
    color:#fafafa;
    font-size: 2rem;
    margin:1rem 0;
}
.btnContainer{
    margin-top: 2rem;
}

@media(max-width:100em){
    
    .inputContainer{
        width:85%;
        
    }
    .linkContainer{
        width:85%;
     
    }
    .emailInput{
        width:96%;
    }
}


@media(max-width:55em){

    .inputContainer{
        margin-top: 1rem;
    }

    .label{
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }

    .error{
        font-size: 1.5rem;
    }
    
    .passwordLink{
        font-size: 1.5rem;
    }

    .inputContainer{
        width:85%;
        
    }
    .linkContainer{
        width:85%;
     
    }
    .emailInput{
        width:94%;
        padding:.5rem;
        font-size: 1.5rem;
    }
    .passwordInput{
        padding:.5rem;
        font-size: 1.5rem;
    }
    .logo{
        width:3.5rem;
        height:3.5rem;
    }
    .infoText{
        font-size: 1.3rem;
    }
    .btnContainer{
        margin-top: 1rem;
    }
}

