.container {
  margin: 0;
  padding:0;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.info {
  text-align: left;
  margin: 2rem 26rem 0 26rem;
}

.infoText {
  margin:1rem 0;
  padding:0;
  font-size: 2.5rem;
  line-height:3.2rem;
}
.row {
  margin: 0;
}

@media (max-width: 150em) {
  .info{
    margin: 2rem 22rem 0 22rem;
  }
  .infoText {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

@media (max-width: 90em) {
  .info{
    margin: 2rem 10rem 0 10rem;
  }
  .infoText {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
}

@media (max-width: 55em) {
  .info{
    margin: 1rem 7rem 0 7rem;
  }

  .infoText {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

@media (max-width: 45em) {
  .info{
    margin: .5rem 2rem 0 2rem;
  }
  .infoText {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
