
.container {
    margin: 0;
    padding-bottom: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label{
    font-size: 2rem;
    margin:0 0 1rem 0;
}

.inputContainer{
    margin:2rem 0 0 0;
    padding:0 1rem 0 1rem;
    width:60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.emailInput{
    width:97.5%;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}
.emailInput:focus{
    outline:none;
}

.passwordInput{
    margin:0;
    width:100%;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}
.passwordInput:focus{
    outline:none;
}

.row{
    display: flex;
    flex-direction: row;
    width:100%;
    margin:0;
    padding:0;
    align-items: center;
    justify-content: space-between;
    gap:0;
}
.logo{
    justify-content: flex-end;
    margin-left: 1rem;
    height:5rem;
    width:5rem;
}

.linkContainer{
    width:60%;
    margin: 2rem 0 .5rem 0;
    text-align: right;
}

.passwordLink{
    display:inline;
    font-size: 2rem;
    cursor:pointer;
}

.passwordLink:hover
{
    font-weight: bold;
}
.error{
    color:orangered;
    font-size: 2rem;
    margin:1rem 0;
}

@media(max-width:100em){
    
    .inputContainer{
        width:85%;
        
    }
    .linkContainer{
        width:85%;
     
    }
    .emailInput{
        width:96%;
    }
}


@media(max-width:55em){

    .inputContainer{
        margin-top: 1rem;
    }

    .label{
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }

    .error{
        font-size: 1.5rem;
    }
    
    .passwordLink{
        font-size: 1.5rem;
    }

    .inputContainer{
        width:85%;
        
    }
    .linkContainer{
        width:85%;
     
    }
    .emailInput{
        width:94%;
        padding:.5rem;
        font-size: 1.5rem;
    }
    .passwordInput{
        padding:.5rem;
        font-size: 1.5rem;
    }
    .logo{
        width:3.5rem;
        height:3.5rem;
    }
}
