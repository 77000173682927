.container {
  z-index: 100;
  width: 13rem;
  background-color: #777777;
  margin: 0;
  transform: rotate(-15deg);
  translate: -2rem 5rem;
  padding: 0.2rem 1rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: #111111;
}
.containerText {
  padding:0;
  margin:.9rem;
  line-height: 2.2rem;
  font-weight: 800;
  font-size: 2rem;
  color:#FAFAFA;
}

@media (max-width: 90em) {
  .container {
    width: 13rem;
    translate: -2rem 4rem;
    padding: 0.1rem .5rem;
  }
  .containerText {
    line-height: 2rem;
    font-weight: 800;
    font-size: 1.8rem;
  }
}

@media (max-width: 55em) {
  .container {
    width: 8.5rem;
    translate: -2rem 3rem;
    padding: 0.1rem .5rem;
    border-width: .3rem;
  }
  .containerText {
    line-height: 1.5rem;
    font-weight: 800;
    font-size: 1.3rem;
  }
}

@media (max-width: 45em) {
  .container {
    width: 7.5rem;
    translate: -2rem 3rem;
    padding: 0.1rem .5rem;
    border-width: .3rem;
  }
  .containerText {
    line-height: 1.3rem;
    font-weight: 800;
    font-size: 1.2rem;
  }
}
