.container{
    margin:0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadingText{
    margin:2rem 0 0 0;
    color:#111111;
    font:2rem;
}

.loadingImg{
    margin:0;
    height:12rem;
}

@media(max-width:55em){
    .loadingImg{
        height:10rem;
    }
}