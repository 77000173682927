
.container {
    margin: 0;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width:100%;
}

.infoText{
    font-size: 2rem;
    line-height: 3rem;
}

.label{
    font-size: 2rem;
    margin:0 0 1rem 0;
}

.infoContainer{
    margin:2rem 2rem 1rem 2rem;
    padding:0 1rem 0 1rem;
    width:60%;
}

.inputContainer{
    margin:.5rem 2rem 3rem 2rem;
    padding:0 1rem 0 1rem;
    width:60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.nameInput{
    width:95%;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}
.nameInput:focus{
    outline:none;
}

.buttonContainer{

}

.error{
    color:orangered;
    font-size: 2rem;
    margin:1rem 0;
}


@media(max-width:100em){
    .infoContainer{
        width:85%;
    }
    .inputContainer{
        width:85%;
    }

}
@media(max-width:55em){
    .error{
        font-size:1.5rem;
    }
    .label{
        font-size: 1.5rem;
    }
    .nameInput{
        font-size: 1.5rem;
    }
    .nameInput{
        width:80%;
        font-size:1.5rem;
    }
    .infoText{
        font-size: 1.5rem;
    }

}