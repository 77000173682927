
.container {
  margin: 1rem 0 0 0;
  padding:1rem .5rem 0 .5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-color: #111111;
  border-style: solid;
  border-width: 1rem;
}
.tagline{
  margin:0 1rem 2rem 1rem;
  padding:0;
  font-family: "BellBold";
  font-size: 2rem;
}

.logo {
  background-color: #FAFAFA;
  height: auto;
  width: 14rem;
}


@media (max-width: 90em) {
  .container{
    border-width: .5rem;
  }
  .logo {
    width: 11rem;
  }
  .tagline{
    font-size: 1.8rem;
  }
}


@media (max-width: 55em) {
  .container{
    margin-top: 0;
  }
  .logo {
    width: 8rem;
    padding: .5rem;
  }
  .tagline{
    margin:0 .5rem 1rem .5rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 45em) {
  .container{
    margin-top: 0;
  }
  .logo {
    width: 6rem;
    padding: .5rem;
  }
  .tagline{
    margin:0 .5rem 1rem .5rem;
    font-size: 1.2rem;
  }
}