.container {
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width:100%;
}

.label{
    font-size: 2rem;
    margin:0 0 1rem 0;
}

.row{
    display: flex;
    flex-direction: row;
    width:60%;
    margin:0;
    padding:0;
    align-items: center;
    justify-content: center;
}

.info{
    font-size: 2rem;
    margin:3rem 0 ;
    line-height: 3rem;
    text-align: left;
}

.infoContainer{
    width:60%;
}

.inputContainer{
    margin:2rem 0 2rem 0;
    padding:0 1rem 0 1rem;
    width:60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.emailInput{
    width:95%;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}
.emailInput:focus{
    outline:none;
}

.error{
    color:orangered;
    font-size: 2rem;
    margin:1rem 0;
}

@media(max-width:55em){

    .inputContainer{
        margin-top: 1rem;
        width: 70%;
    }

    .label{
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }
    .info{
        font-size: 1.5rem;
        margin:2rem 0 ;
        line-height: 2.5rem;
        text-align: left;
    }
    .infoContainer{
        width:80%;
    }
    .emailInput{
        padding:.5rem;
        font-size: 1.5rem;
    }
}