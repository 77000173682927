.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width:100vw;
    height:100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .container {
    border-width: .4rem;
    border-color:#111111;
    border-style: solid;
    background-color: #fafafa;
    padding: 2rem;
    border-radius: 1rem;
    position: relative;
  }
  .btnContainer{
    margin-top:1rem;
  }
  .inputContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .nameInput{
    margin:0;
    border-radius: 1rem;
    padding:1rem;
    border-color: #111111;
    font-size: 2rem;
}

.label{
    font-size: 2rem;
    color:#111111;
    margin:0 0 1rem 0;
}

@media(max-width:55em){
  .label{
    font-size: 1.5rem;
  }
  .nameInput{
    font-size:1.5rem;
  }
  .container{
    padding:1rem;
  }
}

@media(max-width:25em){
  .label{
    font-size: 1.3rem;
  }
  .nameInput{
    font-size:1.3rem;
    padding:.6rem;
  }
  .container{
    padding:1rem;
  }
}